import {Injectable} from "@angular/core";

import {BehaviorSubject, Observable, of, switchMap} from "rxjs";
import {
  ChallengeConfirmCodeGQL,
  ChallengeResponseVm,
  CurrentLeaderGQL,
  CurrentUserGQL,
  LoginGQL,
  UserVm
} from "src/generated/graphql";
import {AuthUtils} from "./auth.utils";


@Injectable()
export class AuthService {
  private _authenticated: boolean = false;
  private userDataSource = new BehaviorSubject<UserVm>(new UserVm());
  user = this.userDataSource.asObservable();

  private gameModelDataSource = new BehaviorSubject<ChallengeResponseVm>(new ChallengeResponseVm());
  gameModel$ = this.gameModelDataSource.asObservable();

  constructor(
    private loginGQL: LoginGQL,
    private currentUserGQL: CurrentUserGQL,
    private currentLeaderGQL: CurrentLeaderGQL,
    public challengeConfirmCodeGQL: ChallengeConfirmCodeGQL,
    // private authenticateGQL: AuthenticateGQL
  ) {
  }

  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  getUser() {
    return this.userDataSource.value;
  }

  signInCpf(cpf: string) {
    return this.loginGQL.mutate({
      input: cpf
    }).pipe(switchMap(({data}) => {
      this._authenticated = true;
      this.accessToken = data?.login?.token as string;
      this.currentUser();
      return of(data);
    }));
  }

  signInLeader(obj: any) {
    return this.challengeConfirmCodeGQL.mutate({
      code: obj.code, challengeId: obj.challengeId
    }).pipe(switchMap(({data}) => {
      this._authenticated = true;
      this.accessToken = data?.challengeConfirmCode?.tokenAccess as string;
      this.currentLeader();
      return of(data);
    }));
  }

  signIn(credentials: { code: string; redirectUri: string; }) {
    /*return this.authenticateGQL.mutate({
      code: credentials.code,
      redirectUri: credentials.redirectUri
    }).pipe(switchMap(({data}) => {
      this._authenticated = true;
      this.accessToken = data?.authenticate?.authorizationToken as string;
      this.currentUser();
      return of(data);
    }));*/
  }

  signInUsingToken(): Observable<any> {
    this.currentUser();
    return of(true);
  }

  signOut(): Observable<any> {
    localStorage.removeItem('accessToken');
    this._authenticated = false;
    return of(true);
  }

  check(): Observable<boolean> {

    if (this._authenticated) {
      return of(true);
    }

    if (!this.accessToken) {
      localStorage.clear();
      return of(false);
    }

    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }
    return this.signInUsingToken();
    // return of(true);
  }

  updateUser(user: UserVm) {
    this.userDataSource.next(user);
  }

  updateGameModel(game: ChallengeResponseVm) {
    console.log('updateGameModel');
    console.log(game);
    this.gameModelDataSource.next(game);
  }

  currentUser() {
    this.currentUserGQL.watch().valueChanges.subscribe(({data}) => {
      // this.updateUser(data.currentUser as UserVm);
      console.log(data);
    }, () => {
      this._authenticated = false;
    });
  }

  currentLeader() {
    const obj: any = {input: localStorage.getItem('challengeId')};
    console.log('currentLeader authService enviando parametro');
    console.log(obj);

    this.currentLeaderGQL.watch(obj).valueChanges.subscribe(({data}) => {
      console.log(data);
    }, () => {
      this._authenticated = false;
    });
  }

}
