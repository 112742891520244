import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "src/app/core/auth/auth.service";
import { BaseComponent } from "src/app/core/base.component";
import { environment } from "src/environments/environment";
import {TranslateService} from "@ngx-translate/core";
import {LoginGQL} from "../../../generated/graphql";
declare var $: any;
@Component({
  selector: "app-authenticate",
  templateUrl: "./authenticate.component.html",
  styleUrls: ["./authenticate.component.scss"],
})
export class AuthenticateComponent extends BaseComponent implements OnInit {
  public token = "";
  public error = "";

  constructor(
    public router: Router,
    public loading: NgxSpinnerService,
    public route: ActivatedRoute,
    private auth: AuthService,
    public translate: TranslateService

  ) {
    super(router, translate);
  }

  ngOnInit() {
    // $("#loaderBox").fadeOut();
    this.route.queryParams.subscribe((data) => {
      if (data["error"] != null) {
        this.error = data["error_description"];
        return;
      }

      if (data["code"] != null) {
        console.log('LOGIN COM CODE');
        this.loginCpf(data["code"]);
        this.router.navigate(["/home"], { queryParams: { id: data["id"] } });
        return;
      }

      if (data["user"] != null) {
        console.log('LOGIN COM USUARIO');
        this.router.navigate(["/login"], { queryParams: { id: data["id"] } });
        return;
      }

    });
  }

  public redirectAuth(): void {
    const clientId = environment.client_id;
    const tenantId = environment.tenant_id;
    const redirectUrl = this.getRedirectUri();
    const authUrl =
      "https://login.microsoftonline.com/" +
      tenantId +
      "/oauth2/v2.0/authorize?client_id=" +
      clientId +
      "&response_type=code&redirect_uri=" +
      redirectUrl +
      "&response_mode=query&scope=openid%20offline_access%20https://graph.microsoft.com/mail.read&state=12345";
    window.location.href = authUrl;
  }

  public getRedirectUri( ){
    const p = window.location.pathname.split("/");
    const virtualPath = p.length >= 3 ? "/" + p[1] : "";
    return  window.location.origin + virtualPath + "/authenticate";
  }

  loginCpf(str: string) {
    this.auth
      .signInCpf(str)
      .subscribe({
        next: (result: any) => {
          console.log('!result?.data?.login?.user?.first_access');
          console.log(!result?.login?.user?.first_access);
          /*if (!result?.login?.user?.first_access) {
            this.router.navigate(["/intro"]);
          } else {
            this.router.navigate(["/home"]);
          }*/
          // this.router.navigate(["/projeto"]);
        },
        error: (err: any) => {
          this.router.navigate(['/login']);
          super.onError(err)
        },
      });
  }

}
