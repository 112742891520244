import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A calendar date that does include a time component. */
  DateTime: any;
  /** A 64-bit, floating-point value. (Min: -1.7976931348623157E+308, Max: 1.7976931348623157E+308) */
  Double: any;
  /** A standard guid (e.g. '6dd43342-ffe6-4964-bb6f-e31c8e50ec86'). */
  Guid: any;
  /** A time of day that does not include a date component. */
  TimeOnly: any;
};

export class AnswerPagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<AnswerVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class AnswerVm {
  answer?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Guid']>;
  image_url?: Maybe<Scalars['String']>;
  is_correct?: Maybe<Scalars['Boolean']>;
  ordination?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Guid']>;
  question_title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export class Area {
  challengeAreas?: Maybe<Array<Maybe<ChallengeArea>>>;
  challengeProjects?: Maybe<Array<Maybe<ChallengeProject>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class AreaVm {
  id?: Maybe<Scalars['Guid']>;
  name?: Maybe<Scalars['String']>;
  projects?: Maybe<Array<Maybe<ChallengeProject>>>;
};

export class CampaignEntity {
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  finished_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Guid']>;
  informative_title?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_finished?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<Scalars['Int']>;
  participate_title?: Maybe<Scalars['String']>;
  path_image?: Maybe<Scalars['String']>;
  path_image_banner?: Maybe<Scalars['String']>;
  path_image_banner_mobile?: Maybe<Scalars['String']>;
  path_image_call?: Maybe<Scalars['String']>;
  path_image_mobile?: Maybe<Scalars['String']>;
  path_regulation?: Maybe<Scalars['String']>;
  primary_color?: Maybe<Scalars['String']>;
  secondary_color?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  update_at?: Maybe<Scalars['DateTime']>;
};

export class CampaignPagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<CampaignVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class CampaignVm {
  active?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  finished?: Maybe<Scalars['Boolean']>;
  finished_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Guid']>;
  informative_title?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['Int']>;
  participate_title?: Maybe<Scalars['String']>;
  path_image?: Maybe<Scalars['String']>;
  path_image_banner?: Maybe<Scalars['String']>;
  path_image_banner_mobile?: Maybe<Scalars['String']>;
  path_image_call?: Maybe<Scalars['String']>;
  path_image_mobile?: Maybe<Scalars['String']>;
  path_regulation?: Maybe<Scalars['String']>;
  primary_color?: Maybe<Scalars['String']>;
  secondary_color?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export class Challenge {
  challengeAreas?: Maybe<Array<Maybe<ChallengeArea>>>;
  challengeExtracts?: Maybe<Array<Maybe<ChallengeExtract>>>;
  challengeProjectAnswers?: Maybe<Array<Maybe<ChallengeProjectAnswer>>>;
  challengeQuestionnaires?: Maybe<Array<Maybe<ChallengeQuestionnaire>>>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Guid'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pathImage?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url_link?: Maybe<Scalars['String']>;
};

export class ChallengeArea {
  area?: Maybe<Area>;
  areaId: Scalars['Guid'];
  challangeId: Scalars['Guid'];
  challenge?: Maybe<Challenge>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Guid'];
};

export class ChallengeDilemmaQuestionnaireVm {
  description?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  selected: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
};

export class ChallengeDilemmaResumeVm {
  description?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  isPaid: Scalars['Boolean'];
  questionnaires?: Maybe<Array<Maybe<ChallengeDilemmaQuestionnaireVm>>>;
  title?: Maybe<Scalars['String']>;
};

export class ChallengeDilemmatVm {
  description?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  questionnaires?: Maybe<Array<Maybe<ChallengeDilemmaQuestionnaireVm>>>;
  title?: Maybe<Scalars['String']>;
};

export class ChallengeExtract {
  challenge?: Maybe<Challenge>;
  challengeId: Scalars['Guid'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Guid'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  token: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['Guid'];
};

export class ChallengePagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<Challenge>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class ChallengeProject {
  area?: Maybe<Area>;
  areaId: Scalars['Guid'];
  challengeProjectAnswer?: Maybe<Array<Maybe<ChallengeProjectAnswer>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  ordination: Scalars['Int'];
  pathImage?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class ChallengeProjectAnswer {
  challangeProjectId: Scalars['Guid'];
  challenge?: Maybe<Challenge>;
  challengeId: Scalars['Guid'];
  challengeProject?: Maybe<ChallengeProject>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Guid'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['Guid'];
};

export class ChallengeProjectVm {
  area?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  ordination: Scalars['Int'];
  selected: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
};

export class ChallengeQuestionnaire {
  challangeId?: Maybe<Scalars['Guid']>;
  challenge?: Maybe<Challenge>;
  challengeQuestionnaireQuestionAnswers?: Maybe<Array<Maybe<ChallengeQuestionnaireQuestionAnswer>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  ordination?: Maybe<Scalars['Int']>;
  responseTime?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  urlVideo?: Maybe<Scalars['String']>;
};

export class ChallengeQuestionnaireAnswerResumeVm {
  answers?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  ordination: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
};

export class ChallengeQuestionnaireQuestionAnswer {
  answer?: Maybe<Scalars['String']>;
  challengeId: Scalars['Guid'];
  challengeQuestionnaire?: Maybe<ChallengeQuestionnaire>;
  challengeQuestionnaireId: Scalars['Guid'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Guid'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['Guid'];
};

export class ChallengeQuestionnaireVm {
  answers?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  ordination: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
};

export class ChallengeResponseVm {
  challengeDilemma?: Maybe<Array<Maybe<ChallengeDilemmatVm>>>;
  challengeId: Scalars['Guid'];
  challengeName?: Maybe<Scalars['String']>;
  challengeProject?: Maybe<Array<Maybe<ChallengeProjectVm>>>;
  challengeQuestionnaire?: Maybe<Array<Maybe<ChallengeQuestionnaireVm>>>;
  challengeTechnology?: Maybe<Array<Maybe<ChallengeTechnologyVm>>>;
  isFinish: Scalars['Boolean'];
  step: Scalars['Int'];
  tokenAccess?: Maybe<Scalars['String']>;
  tokenCurrent: Scalars['Int'];
  tokenDebit: Scalars['Int'];
};

export class ChallengeResumeVm {
  challengeDilemmaResume?: Maybe<Array<Maybe<ChallengeDilemmaResumeVm>>>;
  challengeProject?: Maybe<ChallengeProjectVm>;
  challengeQuestionnaireAnswerResume?: Maybe<Array<Maybe<ChallengeQuestionnaireAnswerResumeVm>>>;
  challengeTeam?: Maybe<Array<Maybe<ChallengeTeamResumeVm>>>;
  challengeTechnologyResume?: Maybe<Array<Maybe<ChallengeTechnologyVmResumeVm>>>;
  token: Scalars['Int'];
};

export class ChallengeTeamResumeVm {
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isLeader: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  urlImage?: Maybe<Scalars['String']>;
};

export class ChallengeTechnologyVm {
  description?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  selected: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
  token: Scalars['Int'];
};

export class ChallengeTechnologyVmResumeVm {
  description?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  title?: Maybe<Scalars['String']>;
  token: Scalars['Int'];
};

export class DefaultMessage {
  message?: Maybe<Scalars['String']>;
};

export class DilemmaDropVm {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Guid']>;
  title?: Maybe<Scalars['String']>;
};

export class GameAnswers {
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Guid']>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export class GameQuestions {
  answers?: Maybe<Array<Maybe<GameAnswers>>>;
  gameHeader?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Guid']>;
  module?: Maybe<Scalars['String']>;
};

export class GameVm {
  games?: Maybe<Array<Maybe<GameQuestions>>>;
  id?: Maybe<Scalars['Guid']>;
  module?: Maybe<Scalars['String']>;
  quiz_id?: Maybe<Scalars['Guid']>;
};

export class Input_AnswerFilterInput {
  answer?: InputMaybe<Scalars['String']>;
  page?: Scalars['Int'];
  question_id?: InputMaybe<Scalars['String']>;
};

export class Input_CampaignFilterInput {
  finished_at?: InputMaybe<Scalars['DateTime']>;
  page?: Scalars['Int'];
  started_at?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_ChallengeChooseDilemmaRequestVm {
  challengeId?: Scalars['Guid'];
  dilemmaId?: Scalars['Guid'];
  dilemmaQuestionnaireId?: Scalars['Guid'];
  token?: Scalars['Int'];
};

export class Input_ChallengeDilemmaQuestionnaireVm {
  description?: InputMaybe<Scalars['String']>;
  id?: Scalars['Guid'];
  selected?: Scalars['Boolean'];
  title?: InputMaybe<Scalars['String']>;
};

export class Input_ChallengeDilemmatVm {
  description?: InputMaybe<Scalars['String']>;
  id?: Scalars['Guid'];
  questionnaires?: InputMaybe<Array<InputMaybe<Input_ChallengeDilemmaQuestionnaireVm>>>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_ChallengeProjectVm {
  area?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: Scalars['Guid'];
  ordination?: Scalars['Int'];
  selected?: Scalars['Boolean'];
  title?: InputMaybe<Scalars['String']>;
};

export class Input_ChallengeQuestionnaireVm {
  answers?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: Scalars['Guid'];
  ordination?: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
};

export class Input_ChallengeResponseVm {
  challengeDilemma?: InputMaybe<Array<InputMaybe<Input_ChallengeDilemmatVm>>>;
  challengeId?: Scalars['Guid'];
  challengeName?: InputMaybe<Scalars['String']>;
  challengeProject?: InputMaybe<Array<InputMaybe<Input_ChallengeProjectVm>>>;
  challengeQuestionnaire?: InputMaybe<Array<InputMaybe<Input_ChallengeQuestionnaireVm>>>;
  challengeTechnology?: InputMaybe<Array<InputMaybe<Input_ChallengeTechnologyVm>>>;
  isFinish?: Scalars['Boolean'];
  step?: Scalars['Int'];
  tokenAccess?: InputMaybe<Scalars['String']>;
  tokenCurrent?: Scalars['Int'];
  tokenDebit?: Scalars['Int'];
};

export class Input_ChallengeTechnologyVm {
  description?: InputMaybe<Scalars['String']>;
  id?: Scalars['Guid'];
  selected?: Scalars['Boolean'];
  title?: InputMaybe<Scalars['String']>;
  token?: Scalars['Int'];
};

export class Input_ChancePasswordInput {
  password?: InputMaybe<Scalars['String']>;
  passwordOld?: InputMaybe<Scalars['String']>;
};

export class Input_CorrectList {
  id?: InputMaybe<Scalars['Guid']>;
  isCorrect?: InputMaybe<Scalars['Boolean']>;
};

export class Input_CreateAnswerVm {
  answer?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  image_url?: InputMaybe<Scalars['String']>;
  is_correct?: InputMaybe<Scalars['Boolean']>;
  ordination?: InputMaybe<Scalars['Int']>;
  question_id?: InputMaybe<Scalars['Guid']>;
  width?: InputMaybe<Scalars['Int']>;
};

export class Input_CreateCampaignVm {
  description?: InputMaybe<Scalars['String']>;
  finished_at?: InputMaybe<Scalars['DateTime']>;
  informative_title?: InputMaybe<Scalars['String']>;
  initials?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['Int']>;
  participate_title?: InputMaybe<Scalars['String']>;
  path_image?: InputMaybe<Scalars['String']>;
  path_image_banner?: InputMaybe<Scalars['String']>;
  path_image_banner_mobile?: InputMaybe<Scalars['String']>;
  path_image_call?: InputMaybe<Scalars['String']>;
  path_image_mobile?: InputMaybe<Scalars['String']>;
  path_regulation?: InputMaybe<Scalars['String']>;
  primary_color?: InputMaybe<Scalars['String']>;
  secondary_color?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_CreateChallengeInput {
  areaId?: Scalars['Guid'];
  dilemmaId?: Scalars['Guid'];
  participants?: InputMaybe<Array<InputMaybe<Input_CreateParticipantUser>>>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_CreateParticipantUser {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
};

export class Input_CreateQuestionVm {
  answers?: InputMaybe<Array<InputMaybe<Input_CreateAnswerVm>>>;
  module?: InputMaybe<Scalars['String']>;
  module_level?: InputMaybe<Scalars['String']>;
  punctuation?: InputMaybe<Scalars['Int']>;
  punctuation_second?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  questionnaire_id?: InputMaybe<Scalars['Guid']>;
};

export class Input_CreateQuestionnaireVm {
  finished_at?: InputMaybe<Scalars['DateTime']>;
  module?: InputMaybe<Scalars['String']>;
  ordination?: InputMaybe<Scalars['Int']>;
  punctuation?: InputMaybe<Scalars['Int']>;
  questions?: InputMaybe<Array<InputMaybe<Input_CreateQuestionVm>>>;
  quiz_id?: InputMaybe<Scalars['Guid']>;
  response_time?: InputMaybe<Scalars['TimeOnly']>;
  started_at?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_CreateQuizVm {
  campaign_id?: InputMaybe<Scalars['Guid']>;
  description?: InputMaybe<Scalars['String']>;
  finished_at?: InputMaybe<Scalars['String']>;
  friendly_url?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_finished?: InputMaybe<Scalars['Boolean']>;
  is_second_chance?: InputMaybe<Scalars['Boolean']>;
  path_banner?: InputMaybe<Scalars['String']>;
  path_banner_mobile?: InputMaybe<Scalars['String']>;
  path_image?: InputMaybe<Scalars['String']>;
  path_image_mobile?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_CreateUserAdminVm {
  birthday?: InputMaybe<Scalars['DateTime']>;
  cpf?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nickName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
};

export class Input_CreateUserVm {
  address?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  cellphone?: InputMaybe<Scalars['String']>;
  cellphone2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  document?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export class Input_GamePostQuestion {
  correctList?: InputMaybe<Array<InputMaybe<Input_CorrectList>>>;
  generalData?: InputMaybe<Input_GeneralData>;
  incorrectList?: InputMaybe<Array<InputMaybe<Input_IncorrectList>>>;
};

export class Input_GamePostVm {
  directToNextBase?: InputMaybe<Scalars['Boolean']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Guid']>;
  module?: InputMaybe<Scalars['String']>;
  percentualTotal?: InputMaybe<Scalars['Double']>;
  questions?: InputMaybe<Array<InputMaybe<Input_GamePostQuestion>>>;
  quiz_id?: InputMaybe<Scalars['Guid']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Guid']>;
};

export class Input_GeneralData {
  id?: InputMaybe<Scalars['Guid']>;
  numberOfCorrect?: InputMaybe<Scalars['Int']>;
  numberOfWrong?: InputMaybe<Scalars['Int']>;
  percentualScore?: InputMaybe<Scalars['Double']>;
  secondsToFinish?: InputMaybe<Scalars['Double']>;
  totalElements?: InputMaybe<Scalars['Int']>;
};

export class Input_GeneratePdInput {
  challengeId?: Scalars['Guid'];
};

export class Input_IncorrectList {
  id?: InputMaybe<Scalars['Guid']>;
  isCorrect?: InputMaybe<Scalars['Boolean']>;
};

export class Input_InitialAccessVm {
  questions?: InputMaybe<Array<InputMaybe<Input_InitialQuestionsVm>>>;
  userId?: Scalars['Guid'];
};

export class Input_InitialAnswersVm {
  id?: InputMaybe<Scalars['Int']>;
  selected?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
};

export class Input_InitialQuestionsVm {
  answers?: InputMaybe<Array<InputMaybe<Input_InitialAnswersVm>>>;
  id?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
};

export class Input_QuestionFilterInput {
  page?: Scalars['Int'];
  question?: InputMaybe<Scalars['String']>;
  questionnaire_id?: InputMaybe<Scalars['String']>;
};

export class Input_QuizAccessVm {
  browser?: InputMaybe<Scalars['String']>;
  ip_origin?: InputMaybe<Scalars['String']>;
  quiz_id?: Scalars['Guid'];
  resolution?: InputMaybe<Scalars['String']>;
  server_origin?: InputMaybe<Scalars['String']>;
  url_origin?: InputMaybe<Scalars['String']>;
  user_id?: Scalars['Guid'];
};

export class Input_QuizListFilterInput {
  campaign_id?: InputMaybe<Scalars['String']>;
  finished_at?: InputMaybe<Scalars['DateTime']>;
  page?: Scalars['Int'];
  started_at?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_QuizListFilterInputAny {
  campaign_id?: InputMaybe<Scalars['String']>;
  finished_at?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Guid']>;
  page?: Scalars['Int'];
  started_at?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_QuizQuestionnaireFilter {
  finished_at?: InputMaybe<Scalars['DateTime']>;
  page?: Scalars['Int'];
  quiz_id?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_RequestLeaderVm {
  challangeId?: InputMaybe<Scalars['String']>;
  id?: Scalars['Guid'];
};

export class Input_ResetPasswordInput {
  code?: InputMaybe<Scalars['String']>;
  newPassword?: InputMaybe<Scalars['String']>;
};

export class Input_SearchAreaInput {
  keyword?: InputMaybe<Scalars['String']>;
};

export class Input_SearchChallengeInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: Scalars['Int'];
  pageSize?: Scalars['Int'];
  start?: InputMaybe<Scalars['DateTime']>;
};

export class Input_UpdateAnswerVm {
  answer?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Guid']>;
  image_url?: InputMaybe<Scalars['String']>;
  is_correct?: InputMaybe<Scalars['Boolean']>;
  ordination?: InputMaybe<Scalars['Int']>;
  question_id?: InputMaybe<Scalars['Guid']>;
  width?: InputMaybe<Scalars['Int']>;
};

export class Input_UpdateCampaignVm {
  description?: InputMaybe<Scalars['String']>;
  finished_at?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Guid']>;
  informative_title?: InputMaybe<Scalars['String']>;
  initials?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['Int']>;
  participate_title?: InputMaybe<Scalars['String']>;
  path_image?: InputMaybe<Scalars['String']>;
  path_image_banner?: InputMaybe<Scalars['String']>;
  path_image_banner_mobile?: InputMaybe<Scalars['String']>;
  path_image_call?: InputMaybe<Scalars['String']>;
  path_image_mobile?: InputMaybe<Scalars['String']>;
  path_regulation?: InputMaybe<Scalars['String']>;
  primary_color?: InputMaybe<Scalars['String']>;
  secondary_color?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_UpdateQuestionVm {
  answers?: InputMaybe<Array<InputMaybe<Input_UpdateAnswerVm>>>;
  id?: InputMaybe<Scalars['Guid']>;
  module?: InputMaybe<Scalars['String']>;
  module_level?: InputMaybe<Scalars['String']>;
  punctuation?: InputMaybe<Scalars['Int']>;
  punctuation_second?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  questionnaire_id?: InputMaybe<Scalars['Guid']>;
};

export class Input_UpdateQuestionnaireVm {
  finished_at?: InputMaybe<Scalars['DateTime']>;
  id?: Scalars['Guid'];
  module?: InputMaybe<Scalars['String']>;
  ordination?: InputMaybe<Scalars['Int']>;
  punctuation?: InputMaybe<Scalars['Int']>;
  questions?: InputMaybe<Array<InputMaybe<Input_UpdateQuestionVm>>>;
  quiz_id?: InputMaybe<Scalars['Guid']>;
  response_time?: InputMaybe<Scalars['TimeOnly']>;
  started_at?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_UpdateQuizVm {
  campaign_id?: InputMaybe<Scalars['Guid']>;
  description?: InputMaybe<Scalars['String']>;
  finished_at?: InputMaybe<Scalars['DateTime']>;
  friendly_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Guid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_finished?: InputMaybe<Scalars['Boolean']>;
  is_second_chance?: InputMaybe<Scalars['Boolean']>;
  path_banner?: InputMaybe<Scalars['String']>;
  path_banner_mobile?: InputMaybe<Scalars['String']>;
  path_image?: InputMaybe<Scalars['String']>;
  path_image_mobile?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_UpdateUserAdminVm {
  birthday?: InputMaybe<Scalars['DateTime']>;
  cpf?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Guid']>;
  name?: InputMaybe<Scalars['String']>;
  nickName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
};

export class Input_UpdateUserVm {
  address?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  cellphone?: InputMaybe<Scalars['String']>;
  cellphone2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  document?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Guid']>;
  name?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export class Input_UserAdminFilterInput {
  email?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: Scalars['Int'];
  start?: InputMaybe<Scalars['DateTime']>;
};

export class Input_UserAnswerFilter {
  answer?: InputMaybe<Scalars['Guid']>;
  page?: Scalars['Int'];
  question?: InputMaybe<Scalars['Guid']>;
  questionnaire?: InputMaybe<Scalars['Guid']>;
  right_answer?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['String']>;
};

export class Input_UserAnswerInsert {
  answer_id?: InputMaybe<Scalars['Guid']>;
  answered?: InputMaybe<Scalars['Boolean']>;
  answered_at?: InputMaybe<Scalars['DateTime']>;
  ordination?: InputMaybe<Scalars['Int']>;
  question_id?: InputMaybe<Scalars['Guid']>;
  quiz_questionnaire_id?: InputMaybe<Scalars['Guid']>;
  right_answer?: InputMaybe<Scalars['Boolean']>;
  right_answer_tentative?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Guid']>;
};

export class Input_UserFilterInput {
  city?: InputMaybe<Scalars['String']>;
  document?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  page?: Scalars['Int'];
  state?: InputMaybe<Scalars['String']>;
};

export class Input_UserQuestionnaireFilter {
  finished_at?: InputMaybe<Scalars['DateTime']>;
  page?: Scalars['Int'];
  questionnaire?: InputMaybe<Scalars['Guid']>;
  quiz?: InputMaybe<Scalars['Guid']>;
  started_at?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['String']>;
};

export class Input_UserQuestionnaireInsert {
  finish?: InputMaybe<Scalars['DateTime']>;
  is_finished?: InputMaybe<Scalars['Boolean']>;
  is_first?: InputMaybe<Scalars['Boolean']>;
  is_time_out?: InputMaybe<Scalars['Boolean']>;
  punctuation?: InputMaybe<Scalars['Int']>;
  questionnaire_module?: InputMaybe<Scalars['String']>;
  quiz_id?: InputMaybe<Scalars['Guid']>;
  quiz_questionnaire_id?: InputMaybe<Scalars['Guid']>;
  start?: InputMaybe<Scalars['DateTime']>;
  time_out_at?: InputMaybe<Scalars['DateTime']>;
  user_id?: InputMaybe<Scalars['Guid']>;
};

export class Input_UserVm {
  address?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  cellphone?: InputMaybe<Scalars['String']>;
  cellphone2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  document?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_access?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Guid']>;
  name?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  profile_type?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export class Mutation {
  challengeChooseDilemma?: Maybe<DefaultMessage>;
  challengeChooseOtherProject?: Maybe<ChallengeResponseVm>;
  challengeConfirmCode?: Maybe<ChallengeResponseVm>;
  challengeUpdate?: Maybe<ChallengeResponseVm>;
  changePassword?: Maybe<DefaultMessage>;
  createAnswer?: Maybe<UpdateAnswerVm>;
  createCampaign?: Maybe<DefaultMessage>;
  createChallenge?: Maybe<DefaultMessage>;
  createQuestion?: Maybe<UpdateQuestionVm>;
  createQuestionnaire?: Maybe<DefaultMessage>;
  createQuiz?: Maybe<DefaultMessage>;
  createUser?: Maybe<DefaultMessage>;
  createUserAdmin?: Maybe<DefaultMessage>;
  deleteAnswer?: Maybe<DefaultMessage>;
  deleteCampaign?: Maybe<DefaultMessage>;
  deleteChallenge?: Maybe<DefaultMessage>;
  deleteQuestion?: Maybe<DefaultMessage>;
  deleteQuestionnaire?: Maybe<DefaultMessage>;
  deleteQuiz?: Maybe<DefaultMessage>;
  deleteUser?: Maybe<DefaultMessage>;
  deleteUserAdmin?: Maybe<DefaultMessage>;
  firstAccess?: Maybe<UserVm>;
  forgotPassword?: Maybe<DefaultMessage>;
  initialAccess?: Maybe<User>;
  insertFirstAccess?: Maybe<UserVm>;
  insertQuizAccess: Scalars['Boolean'];
  insertUserAnswer?: Maybe<DefaultMessage>;
  insertUserQuestionnaire?: Maybe<DefaultMessage>;
  login?: Maybe<User>;
  loginAdmin?: Maybe<UserAdmin>;
  postGame?: Maybe<DefaultMessage>;
  resetPassword?: Maybe<DefaultMessage>;
  setLeader?: Maybe<DefaultMessage>;
  updateAnswer?: Maybe<UpdateAnswerVm>;
  updateCampaign?: Maybe<UpdateCampaignVm>;
  updateQuestion?: Maybe<UpdateQuestionVm>;
  updateQuestionnaire?: Maybe<QuestionnaireVm>;
  updateQuiz?: Maybe<QuizListVm>;
  updateUser?: Maybe<UpdateUserVm>;
  updateUserAdmin?: Maybe<UpdateUserAdminVm>;
};


export class MutationChallengeChooseDilemmaArgs {
  data?: InputMaybe<Input_ChallengeChooseDilemmaRequestVm>;
};


export class MutationChallengeChooseOtherProjectArgs {
  challengeId: Scalars['Guid'];
  dilemmaQuestionnaireId: Scalars['Guid'];
  token: Scalars['Int'];
};


export class MutationChallengeConfirmCodeArgs {
  challengeId: Scalars['Guid'];
  code?: InputMaybe<Scalars['String']>;
};


export class MutationChallengeUpdateArgs {
  input?: InputMaybe<Input_ChallengeResponseVm>;
};


export class MutationChangePasswordArgs {
  input?: InputMaybe<Input_ChancePasswordInput>;
};


export class MutationCreateAnswerArgs {
  input?: InputMaybe<Input_CreateAnswerVm>;
};


export class MutationCreateCampaignArgs {
  input?: InputMaybe<Input_CreateCampaignVm>;
};


export class MutationCreateChallengeArgs {
  input?: InputMaybe<Input_CreateChallengeInput>;
};


export class MutationCreateQuestionArgs {
  input?: InputMaybe<Input_CreateQuestionVm>;
};


export class MutationCreateQuestionnaireArgs {
  input?: InputMaybe<Input_CreateQuestionnaireVm>;
};


export class MutationCreateQuizArgs {
  input?: InputMaybe<Input_CreateQuizVm>;
};


export class MutationCreateUserArgs {
  input?: InputMaybe<Input_CreateUserVm>;
};


export class MutationCreateUserAdminArgs {
  input?: InputMaybe<Input_CreateUserAdminVm>;
};


export class MutationDeleteAnswerArgs {
  id: Scalars['Guid'];
};


export class MutationDeleteCampaignArgs {
  id: Scalars['Guid'];
};


export class MutationDeleteChallengeArgs {
  id: Scalars['Guid'];
};


export class MutationDeleteQuestionArgs {
  id: Scalars['Guid'];
};


export class MutationDeleteQuestionnaireArgs {
  id: Scalars['Guid'];
};


export class MutationDeleteQuizArgs {
  id: Scalars['Guid'];
};


export class MutationDeleteUserArgs {
  id: Scalars['Guid'];
};


export class MutationDeleteUserAdminArgs {
  id: Scalars['Guid'];
};


export class MutationFirstAccessArgs {
  email?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};


export class MutationForgotPasswordArgs {
  email?: InputMaybe<Scalars['String']>;
};


export class MutationInitialAccessArgs {
  input?: InputMaybe<Input_InitialAccessVm>;
};


export class MutationInsertFirstAccessArgs {
  input?: InputMaybe<Input_UserVm>;
};


export class MutationInsertQuizAccessArgs {
  input?: InputMaybe<Input_QuizAccessVm>;
};


export class MutationInsertUserAnswerArgs {
  input?: InputMaybe<Input_UserAnswerInsert>;
};


export class MutationInsertUserQuestionnaireArgs {
  input?: InputMaybe<Input_UserQuestionnaireInsert>;
};


export class MutationLoginArgs {
  code?: InputMaybe<Scalars['String']>;
};


export class MutationLoginAdminArgs {
  cpf?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};


export class MutationPostGameArgs {
  game?: InputMaybe<Input_GamePostVm>;
};


export class MutationResetPasswordArgs {
  input?: InputMaybe<Input_ResetPasswordInput>;
};


export class MutationSetLeaderArgs {
  data?: InputMaybe<Input_RequestLeaderVm>;
};


export class MutationUpdateAnswerArgs {
  input?: InputMaybe<Input_UpdateAnswerVm>;
};


export class MutationUpdateCampaignArgs {
  input?: InputMaybe<Input_UpdateCampaignVm>;
};


export class MutationUpdateQuestionArgs {
  input?: InputMaybe<Input_UpdateQuestionVm>;
};


export class MutationUpdateQuestionnaireArgs {
  input?: InputMaybe<Input_UpdateQuestionnaireVm>;
};


export class MutationUpdateQuizArgs {
  input?: InputMaybe<Input_UpdateQuizVm>;
};


export class MutationUpdateUserArgs {
  input?: InputMaybe<Input_UpdateUserVm>;
};


export class MutationUpdateUserAdminArgs {
  input?: InputMaybe<Input_UpdateUserAdminVm>;
};

export class Query {
  challengeResume?: Maybe<ChallengeResumeVm>;
  challengeTechnologyResume?: Maybe<Array<Maybe<ChallengeTechnologyVm>>>;
  currentLeader?: Maybe<ChallengeResponseVm>;
  currentUser?: Maybe<UserVm>;
  currentUserAdmin?: Maybe<UserAdminVm>;
  findChallengeLeader?: Maybe<ResponseUserParticipantVm>;
  getAnswer?: Maybe<Array<Maybe<QuestionnaireAnswerEntity>>>;
  getAnswerById?: Maybe<AnswerVm>;
  getAnswerFilter?: Maybe<AnswerPagination>;
  getAnyQuiz?: Maybe<QuizPagination>;
  getCampaign?: Maybe<Array<Maybe<CampaignEntity>>>;
  getCampaignById?: Maybe<CampaignVm>;
  getCampaignFilter?: Maybe<CampaignPagination>;
  getGame?: Maybe<GameVm>;
  getQuestion?: Maybe<Array<Maybe<QuestionnaireQuestionEntity>>>;
  getQuestionById?: Maybe<QuestionVm>;
  getQuestionFilter?: Maybe<QuestionPagination>;
  getQuestionnaire?: Maybe<Array<Maybe<QuizQuestionnaireVm>>>;
  getQuestionnaireById?: Maybe<QuestionnaireVm>;
  getQuestionnaireFilter?: Maybe<QuizQuestionnairePagination>;
  getQuiz?: Maybe<Array<Maybe<QuizEntity>>>;
  getQuizById?: Maybe<QuizListVm>;
  getQuizFilter?: Maybe<QuizPagination>;
  getUser?: Maybe<Array<Maybe<UserEntity>>>;
  getUserAdmin?: Maybe<Array<Maybe<UserAdminEntity>>>;
  getUserAdminById?: Maybe<UserAdminVm>;
  getUserById?: Maybe<UserVm>;
  getUserFilter?: Maybe<UserPagination>;
  listArea?: Maybe<Array<Maybe<AreaVm>>>;
  listDilemma?: Maybe<Array<Maybe<DilemmaDropVm>>>;
  listSex?: Maybe<Array<Maybe<SexVm>>>;
  questionnaireByQuizId?: Maybe<QuizQuestionnairePagination>;
  searchChallenge?: Maybe<ChallengePagination>;
  searchParticipants?: Maybe<Array<Maybe<ResponseUserParticipantVm>>>;
  userAnswerFilter?: Maybe<UserAnswerPagination>;
  userProgress?: Maybe<UserProgressVm>;
  userQuestionnaireFilter?: Maybe<UserQuestionnairePagination>;
  usersAdmin?: Maybe<UserAdminPagination>;
};


export class QueryChallengeResumeArgs {
  challengeId?: InputMaybe<Input_GeneratePdInput>;
};


export class QueryChallengeTechnologyResumeArgs {
  challengeId: Scalars['Guid'];
};


export class QueryCurrentLeaderArgs {
  challengeId: Scalars['Guid'];
};


export class QueryFindChallengeLeaderArgs {
  challengeId: Scalars['Guid'];
};


export class QueryGetAnswerByIdArgs {
  id: Scalars['Guid'];
};


export class QueryGetAnswerFilterArgs {
  input?: InputMaybe<Input_AnswerFilterInput>;
};


export class QueryGetAnyQuizArgs {
  input?: InputMaybe<Input_QuizListFilterInputAny>;
};


export class QueryGetCampaignByIdArgs {
  id: Scalars['Guid'];
};


export class QueryGetCampaignFilterArgs {
  input?: InputMaybe<Input_CampaignFilterInput>;
};


export class QueryGetGameArgs {
  module?: InputMaybe<Scalars['String']>;
};


export class QueryGetQuestionByIdArgs {
  id: Scalars['Guid'];
};


export class QueryGetQuestionFilterArgs {
  input?: InputMaybe<Input_QuestionFilterInput>;
};


export class QueryGetQuestionnaireByIdArgs {
  id: Scalars['Guid'];
};


export class QueryGetQuestionnaireFilterArgs {
  input?: InputMaybe<Input_QuizQuestionnaireFilter>;
};


export class QueryGetQuizByIdArgs {
  id: Scalars['Guid'];
};


export class QueryGetQuizFilterArgs {
  input?: InputMaybe<Input_QuizListFilterInput>;
};


export class QueryGetUserAdminByIdArgs {
  id: Scalars['Guid'];
};


export class QueryGetUserByIdArgs {
  id: Scalars['Guid'];
};


export class QueryGetUserFilterArgs {
  input?: InputMaybe<Input_UserFilterInput>;
};


export class QueryListAreaArgs {
  input?: InputMaybe<Input_SearchAreaInput>;
};


export class QueryQuestionnaireByQuizIdArgs {
  page: Scalars['Int'];
  quizId: Scalars['Guid'];
};


export class QuerySearchChallengeArgs {
  input?: InputMaybe<Input_SearchChallengeInput>;
};


export class QuerySearchParticipantsArgs {
  challengeId: Scalars['Guid'];
};


export class QueryUserAnswerFilterArgs {
  input?: InputMaybe<Input_UserAnswerFilter>;
};


export class QueryUserProgressArgs {
  userId: Scalars['Guid'];
};


export class QueryUserQuestionnaireFilterArgs {
  input?: InputMaybe<Input_UserQuestionnaireFilter>;
};


export class QueryUsersAdminArgs {
  input?: InputMaybe<Input_UserAdminFilterInput>;
};

export class QuestionPagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<QuestionVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class QuestionVm {
  id?: Maybe<Scalars['Guid']>;
  module?: Maybe<Scalars['String']>;
  module_level?: Maybe<Scalars['String']>;
  punctuation?: Maybe<Scalars['Int']>;
  punctuation_second?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  questionnaire_id?: Maybe<Scalars['Guid']>;
  questionnaire_title?: Maybe<Scalars['String']>;
};

export class QuestionnaireAnswerEntity {
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Guid']>;
  image_url?: Maybe<Scalars['String']>;
  is_correct?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  ordination?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Guid']>;
  update_at?: Maybe<Scalars['DateTime']>;
  width?: Maybe<Scalars['Int']>;
};

export class QuestionnaireAnswerVm {
  answer?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Guid']>;
  image_url?: Maybe<Scalars['String']>;
  is_correct?: Maybe<Scalars['Boolean']>;
  ordination?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Guid']>;
  question_title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export class QuestionnaireQuestionEntity {
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['Guid'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_second_chance?: Maybe<Scalars['Boolean']>;
  module?: Maybe<Scalars['String']>;
  module_level?: Maybe<Scalars['String']>;
  punctuation?: Maybe<Scalars['Int']>;
  punctuation_second?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  questionnaire_id?: Maybe<Scalars['Guid']>;
  update_at?: Maybe<Scalars['DateTime']>;
};

export class QuestionnaireQuestionVm {
  answers?: Maybe<Array<Maybe<QuestionnaireAnswerVm>>>;
  id?: Maybe<Scalars['Guid']>;
  module?: Maybe<Scalars['String']>;
  module_level?: Maybe<Scalars['String']>;
  punctuation?: Maybe<Scalars['Int']>;
  punctuation_second?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  questionnaire_id?: Maybe<Scalars['Guid']>;
  questionnaire_title?: Maybe<Scalars['String']>;
};

export class QuestionnaireVm {
  finished_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Guid']>;
  module?: Maybe<Scalars['String']>;
  ordination?: Maybe<Scalars['Int']>;
  punctuation?: Maybe<Scalars['Int']>;
  questions?: Maybe<Array<Maybe<QuestionnaireQuestionVm>>>;
  quiz_id?: Maybe<Scalars['Guid']>;
  response_time?: Maybe<Scalars['TimeOnly']>;
  started_at?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export class QuizEntity {
  campaign_id?: Maybe<Scalars['Guid']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  finished_at?: Maybe<Scalars['DateTime']>;
  friendly_url?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  is_active?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_finished?: Maybe<Scalars['Boolean']>;
  is_second_chance?: Maybe<Scalars['Boolean']>;
  path_image?: Maybe<Scalars['String']>;
  path_image_banner?: Maybe<Scalars['String']>;
  path_image_banner_mobile?: Maybe<Scalars['String']>;
  path_image_mobile?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  update_at?: Maybe<Scalars['DateTime']>;
};

export class QuizListVm {
  active?: Maybe<Scalars['Boolean']>;
  campaign_id?: Maybe<Scalars['Guid']>;
  campaign_title?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  finished?: Maybe<Scalars['Boolean']>;
  finished_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Guid']>;
  is_second_chance?: Maybe<Scalars['Boolean']>;
  path_image?: Maybe<Scalars['String']>;
  path_image_banner?: Maybe<Scalars['String']>;
  path_image_banner_mobile?: Maybe<Scalars['String']>;
  path_image_mobile?: Maybe<Scalars['String']>;
  path_link?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export class QuizPagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<QuizListVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class QuizQuestionnaireListVm {
  finished_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Guid']>;
  ordination?: Maybe<Scalars['Int']>;
  punctuation?: Maybe<Scalars['Int']>;
  quiz_id?: Maybe<Scalars['Guid']>;
  quiz_title?: Maybe<Scalars['String']>;
  response_time?: Maybe<Scalars['TimeOnly']>;
  started_at?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export class QuizQuestionnairePagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<QuizQuestionnaireListVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class QuizQuestionnaireVm {
  finished_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Guid']>;
  ordination?: Maybe<Scalars['Int']>;
  punctuation?: Maybe<Scalars['Int']>;
  quiz_id?: Maybe<Scalars['Guid']>;
  response_time?: Maybe<Scalars['TimeOnly']>;
  started_at?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export class ResponseUserParticipantVm {
  challengeName?: Maybe<Scalars['String']>;
  challengedId: Scalars['Guid'];
  code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  name?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
};

export class SexVm {
  abbreviation?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  name?: Maybe<Scalars['String']>;
};

export class UpdateAnswerVm {
  answer?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Guid']>;
  image_url?: Maybe<Scalars['String']>;
  is_correct?: Maybe<Scalars['Boolean']>;
  ordination?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Guid']>;
  width?: Maybe<Scalars['Int']>;
};

export class UpdateCampaignVm {
  description?: Maybe<Scalars['String']>;
  finished_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Guid']>;
  informative_title?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['Int']>;
  participate_title?: Maybe<Scalars['String']>;
  path_image?: Maybe<Scalars['String']>;
  path_image_banner?: Maybe<Scalars['String']>;
  path_image_banner_mobile?: Maybe<Scalars['String']>;
  path_image_call?: Maybe<Scalars['String']>;
  path_image_mobile?: Maybe<Scalars['String']>;
  path_regulation?: Maybe<Scalars['String']>;
  primary_color?: Maybe<Scalars['String']>;
  secondary_color?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export class UpdateQuestionVm {
  answers?: Maybe<Array<Maybe<UpdateAnswerVm>>>;
  id?: Maybe<Scalars['Guid']>;
  module?: Maybe<Scalars['String']>;
  module_level?: Maybe<Scalars['String']>;
  punctuation?: Maybe<Scalars['Int']>;
  punctuation_second?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  questionnaire_id?: Maybe<Scalars['Guid']>;
};

export class UpdateUserAdminVm {
  birthday?: Maybe<Scalars['DateTime']>;
  cpf?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Guid']>;
  name?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
};

export class UpdateUserVm {
  address?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  cellphone?: Maybe<Scalars['String']>;
  cellphone2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Guid']>;
  name?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export class User {
  token?: Maybe<Scalars['String']>;
  user?: Maybe<UserVm>;
};

export class UserAdmin {
  token?: Maybe<Scalars['String']>;
  userAdmin?: Maybe<UserAdminVm>;
};

export class UserAdminEntity {
  birthday?: Maybe<Scalars['DateTime']>;
  codeFirstAccess?: Maybe<Scalars['String']>;
  codeFirstAccessExpiration?: Maybe<Scalars['DateTime']>;
  cpf?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstAccess?: Maybe<Scalars['Boolean']>;
  firstAccessAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Guid'];
  name?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  resetPasswordExpiration?: Maybe<Scalars['DateTime']>;
  resetPasswordToken?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class UserAdminPagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<UserAdminVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class UserAdminVm {
  birthday?: Maybe<Scalars['DateTime']>;
  cpf?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstAccess?: Maybe<Scalars['Boolean']>;
  firstAccessAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Guid'];
  name?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class UserAnswerPagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<UserAnswerVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class UserAnswerVm {
  answer_id?: Maybe<Scalars['Guid']>;
  answer_title?: Maybe<Scalars['String']>;
  answered?: Maybe<Scalars['Boolean']>;
  answered_at?: Maybe<Scalars['DateTime']>;
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Guid']>;
  ordination?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Guid']>;
  question_title?: Maybe<Scalars['String']>;
  quiz_questionnaire_id?: Maybe<Scalars['Guid']>;
  quiz_questionnaire_title?: Maybe<Scalars['String']>;
  right_answer?: Maybe<Scalars['Boolean']>;
  right_answer_tentative?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Guid']>;
  user_name?: Maybe<Scalars['String']>;
};

export class UserEntity {
  address?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  cellphone?: Maybe<Scalars['String']>;
  cellphone2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  document?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_access?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Guid']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  profile_type?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  update_at?: Maybe<Scalars['DateTime']>;
  zipcode?: Maybe<Scalars['String']>;
};

export class UserPagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<UserVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class UserProgressAnswer {
  answerAt?: Maybe<Scalars['DateTime']>;
  baseCamp?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  punctuation?: Maybe<Scalars['Int']>;
  questionId?: Maybe<Scalars['Guid']>;
  responseTime?: Maybe<Scalars['TimeOnly']>;
  rightAnswer?: Maybe<Scalars['Boolean']>;
};

export class UserProgressVm {
  baseUrlGame?: Maybe<Scalars['String']>;
  questionnaires?: Maybe<Array<Maybe<UserQuestionnaireProgress>>>;
  quizId?: Maybe<Scalars['Guid']>;
  totalPoints?: Maybe<Scalars['Int']>;
  userId: Scalars['Guid'];
};

export class UserQuestionnairePagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<UserQuestionnaireVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class UserQuestionnaireProgress {
  answers?: Maybe<Array<Maybe<UserProgressAnswer>>>;
  id?: Maybe<Scalars['Guid']>;
  isFinish?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

export class UserQuestionnaireVm {
  finish?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Guid']>;
  is_finished?: Maybe<Scalars['Boolean']>;
  is_first?: Maybe<Scalars['Boolean']>;
  is_time_out?: Maybe<Scalars['Boolean']>;
  punctuation?: Maybe<Scalars['Int']>;
  questionnaire_title?: Maybe<Scalars['String']>;
  quiz_id?: Maybe<Scalars['Guid']>;
  quiz_questionnaire_id?: Maybe<Scalars['Guid']>;
  quiz_title?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  time_out_at?: Maybe<Scalars['DateTime']>;
  user_document?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Guid']>;
  user_name?: Maybe<Scalars['String']>;
};

export class UserVm {
  address?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  cellphone?: Maybe<Scalars['String']>;
  cellphone2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_access?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Guid']>;
  name?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  profile_type?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type DefaultMessageFragment = { message?: string | null };

export type UserFragment = { token?: string | null, user?: { id?: any | null, name?: string | null, birthday?: any | null, sex?: string | null, cellphone?: string | null, cellphone2?: string | null, zipcode?: string | null, address?: string | null, neighborhood?: string | null, city?: string | null, state?: string | null, email?: string | null, document?: string | null, first_access?: boolean | null, profile_type?: string | null } | null };

export type UserVmFragment = { id?: any | null, name?: string | null, birthday?: any | null, sex?: string | null, cellphone?: string | null, cellphone2?: string | null, zipcode?: string | null, address?: string | null, neighborhood?: string | null, city?: string | null, state?: string | null, email?: string | null, document?: string | null, first_access?: boolean | null, profile_type?: string | null };

export type UserProgressVmFragment = { userId: any, quizId?: any | null, baseUrlGame?: string | null, totalPoints?: number | null, questionnaires?: Array<{ id?: any | null, title?: string | null, isFinish?: boolean | null, answers?: Array<{ questionId?: any | null, baseCamp?: number | null, level?: number | null, answerAt?: any | null, responseTime?: any | null, punctuation?: number | null, rightAnswer?: boolean | null } | null> | null } | null> | null };

export type UserQuestionnaireProgressFragment = { id?: any | null, title?: string | null, isFinish?: boolean | null, answers?: Array<{ questionId?: any | null, baseCamp?: number | null, level?: number | null, answerAt?: any | null, responseTime?: any | null, punctuation?: number | null, rightAnswer?: boolean | null } | null> | null };

export type UserProgressAnswerFragment = { questionId?: any | null, baseCamp?: number | null, level?: number | null, answerAt?: any | null, responseTime?: any | null, punctuation?: number | null, rightAnswer?: boolean | null };

export type ResponseUserParticipantVmFragment = { id: any, name?: string | null, email?: string | null, sex?: string | null, challengedId: any, code?: string | null, challengeName?: string | null };

export type ChallengeResponseVmFragment = { challengeId: any, tokenCurrent: number, tokenDebit: number, step: number, isFinish: boolean, tokenAccess?: string | null, challengeName?: string | null, challengeProject?: Array<{ area?: string | null, id: any, title?: string | null, description?: string | null, ordination: number, selected: boolean } | null> | null, challengeDilemma?: Array<{ id: any, title?: string | null, description?: string | null, questionnaires?: Array<{ id: any, title?: string | null, description?: string | null, selected: boolean } | null> | null } | null> | null, challengeTechnology?: Array<{ id: any, title?: string | null, description?: string | null, token: number, selected: boolean } | null> | null, challengeQuestionnaire?: Array<{ id: any, title?: string | null, description?: string | null, answers?: string | null, ordination: number } | null> | null };

export type ChallengeResumeVmFragment = { token: number, challengeProject?: { area?: string | null, id: any, title?: string | null, description?: string | null, ordination: number, selected: boolean } | null, challengeTeam?: Array<{ name?: string | null, sex?: string | null, email?: string | null, isLeader: boolean } | null> | null, challengeDilemmaResume?: Array<{ id: any, title?: string | null, description?: string | null, questionnaires?: Array<{ id: any, title?: string | null, description?: string | null, selected: boolean } | null> | null } | null> | null, challengeTechnologyResume?: Array<{ id: any, title?: string | null, description?: string | null, token: number } | null> | null, challengeQuestionnaireAnswerResume?: Array<{ id: any, title?: string | null, description?: string | null, answers?: string | null, ordination: number } | null> | null };

export type CurrentLeaderQueryVariables = Exact<{
  input: Scalars['Guid'];
}>;


export type CurrentLeaderQuery = { currentLeader?: { challengeId: any, tokenCurrent: number, tokenDebit: number, step: number, isFinish: boolean, tokenAccess?: string | null, challengeName?: string | null, challengeProject?: Array<{ area?: string | null, id: any, title?: string | null, description?: string | null, ordination: number, selected: boolean } | null> | null, challengeDilemma?: Array<{ id: any, title?: string | null, description?: string | null, questionnaires?: Array<{ id: any, title?: string | null, description?: string | null, selected: boolean } | null> | null } | null> | null, challengeTechnology?: Array<{ id: any, title?: string | null, description?: string | null, token: number, selected: boolean } | null> | null, challengeQuestionnaire?: Array<{ id: any, title?: string | null, description?: string | null, answers?: string | null, ordination: number } | null> | null } | null };

export type SearchParticipantsQueryVariables = Exact<{
  input: Scalars['Guid'];
}>;


export type SearchParticipantsQuery = { searchParticipants?: Array<{ id: any, name?: string | null, email?: string | null, sex?: string | null, challengedId: any, code?: string | null, challengeName?: string | null } | null> | null };

export type SetLeaderMutationVariables = Exact<{
  input: Input_RequestLeaderVm;
}>;


export type SetLeaderMutation = { setLeader?: { message?: string | null } | null };

export type ChallengeConfirmCodeMutationVariables = Exact<{
  challengeId: Scalars['Guid'];
  code?: InputMaybe<Scalars['String']>;
}>;


export type ChallengeConfirmCodeMutation = { challengeConfirmCode?: { challengeId: any, tokenCurrent: number, tokenDebit: number, step: number, isFinish: boolean, tokenAccess?: string | null, challengeName?: string | null, challengeProject?: Array<{ area?: string | null, id: any, title?: string | null, description?: string | null, ordination: number, selected: boolean } | null> | null, challengeDilemma?: Array<{ id: any, title?: string | null, description?: string | null, questionnaires?: Array<{ id: any, title?: string | null, description?: string | null, selected: boolean } | null> | null } | null> | null, challengeTechnology?: Array<{ id: any, title?: string | null, description?: string | null, token: number, selected: boolean } | null> | null, challengeQuestionnaire?: Array<{ id: any, title?: string | null, description?: string | null, answers?: string | null, ordination: number } | null> | null } | null };

export type ChallengeUpdateMutationVariables = Exact<{
  input: Input_ChallengeResponseVm;
}>;


export type ChallengeUpdateMutation = { challengeUpdate?: { challengeId: any, tokenCurrent: number, tokenDebit: number, step: number, isFinish: boolean, tokenAccess?: string | null, challengeName?: string | null, challengeProject?: Array<{ area?: string | null, id: any, title?: string | null, description?: string | null, ordination: number, selected: boolean } | null> | null, challengeDilemma?: Array<{ id: any, title?: string | null, description?: string | null, questionnaires?: Array<{ id: any, title?: string | null, description?: string | null, selected: boolean } | null> | null } | null> | null, challengeTechnology?: Array<{ id: any, title?: string | null, description?: string | null, token: number, selected: boolean } | null> | null, challengeQuestionnaire?: Array<{ id: any, title?: string | null, description?: string | null, answers?: string | null, ordination: number } | null> | null } | null };

export type FindChallengeLeaderQueryVariables = Exact<{
  input: Scalars['Guid'];
}>;


export type FindChallengeLeaderQuery = { findChallengeLeader?: { id: any, name?: string | null, email?: string | null, sex?: string | null, challengedId: any, code?: string | null, challengeName?: string | null } | null };

export type ChallengeTechnologyResumeQueryVariables = Exact<{
  input: Scalars['Guid'];
}>;


export type ChallengeTechnologyResumeQuery = { challengeTechnologyResume?: Array<{ id: any, token: number, description?: string | null, selected: boolean, title?: string | null } | null> | null };

export type ChallengeResumeQueryVariables = Exact<{
  input: Input_GeneratePdInput;
}>;


export type ChallengeResumeQuery = { challengeResume?: { token: number, challengeProject?: { area?: string | null, id: any, title?: string | null, description?: string | null, ordination: number, selected: boolean } | null, challengeTeam?: Array<{ name?: string | null, sex?: string | null, email?: string | null, isLeader: boolean } | null> | null, challengeDilemmaResume?: Array<{ id: any, title?: string | null, description?: string | null, questionnaires?: Array<{ id: any, title?: string | null, description?: string | null, selected: boolean } | null> | null } | null> | null, challengeTechnologyResume?: Array<{ id: any, title?: string | null, description?: string | null, token: number } | null> | null, challengeQuestionnaireAnswerResume?: Array<{ id: any, title?: string | null, description?: string | null, answers?: string | null, ordination: number } | null> | null } | null };

export type ChallengeChooseOtherProjectMutationVariables = Exact<{
  challengeId: Scalars['Guid'];
  answerId: Scalars['Guid'];
  token: Scalars['Int'];
}>;


export type ChallengeChooseOtherProjectMutation = { challengeChooseOtherProject?: { challengeId: any, tokenCurrent: number, tokenDebit: number, step: number, isFinish: boolean, tokenAccess?: string | null, challengeName?: string | null, challengeProject?: Array<{ area?: string | null, id: any, title?: string | null, description?: string | null, ordination: number, selected: boolean } | null> | null, challengeDilemma?: Array<{ id: any, title?: string | null, description?: string | null, questionnaires?: Array<{ id: any, title?: string | null, description?: string | null, selected: boolean } | null> | null } | null> | null, challengeTechnology?: Array<{ id: any, title?: string | null, description?: string | null, token: number, selected: boolean } | null> | null, challengeQuestionnaire?: Array<{ id: any, title?: string | null, description?: string | null, answers?: string | null, ordination: number } | null> | null } | null };

export type ChallengeChooseDilemmaMutationVariables = Exact<{
  input?: InputMaybe<Input_ChallengeChooseDilemmaRequestVm>;
}>;


export type ChallengeChooseDilemmaMutation = { challengeChooseDilemma?: { message?: string | null } | null };

export type InitialAccessMutationVariables = Exact<{
  input?: InputMaybe<Input_InitialAccessVm>;
}>;


export type InitialAccessMutation = { initialAccess?: { token?: string | null, user?: { id?: any | null, name?: string | null, birthday?: any | null, sex?: string | null, cellphone?: string | null, cellphone2?: string | null, zipcode?: string | null, address?: string | null, neighborhood?: string | null, city?: string | null, state?: string | null, email?: string | null, document?: string | null, first_access?: boolean | null, profile_type?: string | null } | null } | null };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { currentUser?: { id?: any | null, name?: string | null, birthday?: any | null, sex?: string | null, cellphone?: string | null, cellphone2?: string | null, zipcode?: string | null, address?: string | null, neighborhood?: string | null, city?: string | null, state?: string | null, email?: string | null, document?: string | null, first_access?: boolean | null, profile_type?: string | null } | null };

export type UserProgressQueryVariables = Exact<{
  input: Scalars['Guid'];
}>;


export type UserProgressQuery = { userProgress?: { userId: any, quizId?: any | null, baseUrlGame?: string | null, totalPoints?: number | null, questionnaires?: Array<{ id?: any | null, title?: string | null, isFinish?: boolean | null, answers?: Array<{ questionId?: any | null, baseCamp?: number | null, level?: number | null, answerAt?: any | null, responseTime?: any | null, punctuation?: number | null, rightAnswer?: boolean | null } | null> | null } | null> | null } | null };

export type LoginMutationVariables = Exact<{
  input?: InputMaybe<Scalars['String']>;
}>;


export type LoginMutation = { login?: { token?: string | null, user?: { id?: any | null, name?: string | null, birthday?: any | null, sex?: string | null, cellphone?: string | null, cellphone2?: string | null, zipcode?: string | null, address?: string | null, neighborhood?: string | null, city?: string | null, state?: string | null, email?: string | null, document?: string | null, first_access?: boolean | null, profile_type?: string | null } | null } | null };

export const DefaultMessageFragmentDoc = gql`
    fragment DefaultMessage on DefaultMessage {
  message
}
    `;
export const UserVmFragmentDoc = gql`
    fragment UserVM on UserVM {
  id
  name
  birthday
  sex
  cellphone
  cellphone2
  zipcode
  address
  neighborhood
  city
  state
  email
  document
  first_access
  profile_type
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  token
  user {
    ...UserVM
  }
}
    ${UserVmFragmentDoc}`;
export const UserProgressAnswerFragmentDoc = gql`
    fragment UserProgressAnswer on UserProgressAnswer {
  questionId
  baseCamp
  level
  answerAt
  responseTime
  punctuation
  rightAnswer
}
    `;
export const UserQuestionnaireProgressFragmentDoc = gql`
    fragment UserQuestionnaireProgress on UserQuestionnaireProgress {
  id
  title
  isFinish
  answers {
    ...UserProgressAnswer
  }
}
    ${UserProgressAnswerFragmentDoc}`;
export const UserProgressVmFragmentDoc = gql`
    fragment UserProgressVM on UserProgressVM {
  userId
  quizId
  baseUrlGame
  totalPoints
  questionnaires {
    ...UserQuestionnaireProgress
  }
}
    ${UserQuestionnaireProgressFragmentDoc}`;
export const ResponseUserParticipantVmFragmentDoc = gql`
    fragment ResponseUserParticipantVM on ResponseUserParticipantVM {
  id
  name
  email
  sex
  challengedId
  code
  challengeName
}
    `;
export const ChallengeResponseVmFragmentDoc = gql`
    fragment ChallengeResponseVM on ChallengeResponseVM {
  challengeId
  tokenCurrent
  tokenDebit
  step
  isFinish
  tokenAccess
  challengeName
  challengeProject {
    area
    id
    title
    description
    ordination
    selected
  }
  challengeDilemma {
    id
    title
    description
    questionnaires {
      id
      title
      description
      selected
    }
  }
  challengeTechnology {
    id
    title
    description
    token
    selected
  }
  challengeQuestionnaire {
    id
    title
    description
    answers
    ordination
  }
}
    `;
export const ChallengeResumeVmFragmentDoc = gql`
    fragment ChallengeResumeVm on ChallengeResumeVm {
  token
  challengeProject {
    area
    id
    title
    description
    ordination
    selected
  }
  challengeTeam {
    name
    sex
    email
    isLeader
  }
  challengeDilemmaResume {
    id
    title
    description
    questionnaires {
      id
      title
      description
      selected
    }
  }
  challengeTechnologyResume {
    id
    title
    description
    token
  }
  challengeQuestionnaireAnswerResume {
    id
    title
    description
    answers
    ordination
  }
}
    `;
export const CurrentLeaderDocument = gql`
    query currentLeader($input: Guid!) {
  currentLeader(challengeId: $input) {
    ...ChallengeResponseVM
  }
}
    ${ChallengeResponseVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentLeaderGQL extends Apollo.Query<CurrentLeaderQuery, CurrentLeaderQueryVariables> {
    document = CurrentLeaderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchParticipantsDocument = gql`
    query searchParticipants($input: Guid!) {
  searchParticipants(challengeId: $input) {
    ...ResponseUserParticipantVM
  }
}
    ${ResponseUserParticipantVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchParticipantsGQL extends Apollo.Query<SearchParticipantsQuery, SearchParticipantsQueryVariables> {
    document = SearchParticipantsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetLeaderDocument = gql`
    mutation setLeader($input: Input_RequestLeaderVM!) {
  setLeader(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SetLeaderGQL extends Apollo.Mutation<SetLeaderMutation, SetLeaderMutationVariables> {
    document = SetLeaderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChallengeConfirmCodeDocument = gql`
    mutation challengeConfirmCode($challengeId: Guid!, $code: String) {
  challengeConfirmCode(challengeId: $challengeId, code: $code) {
    ...ChallengeResponseVM
  }
}
    ${ChallengeResponseVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChallengeConfirmCodeGQL extends Apollo.Mutation<ChallengeConfirmCodeMutation, ChallengeConfirmCodeMutationVariables> {
    document = ChallengeConfirmCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChallengeUpdateDocument = gql`
    mutation challengeUpdate($input: Input_ChallengeResponseVM!) {
  challengeUpdate(input: $input) {
    ...ChallengeResponseVM
  }
}
    ${ChallengeResponseVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChallengeUpdateGQL extends Apollo.Mutation<ChallengeUpdateMutation, ChallengeUpdateMutationVariables> {
    document = ChallengeUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindChallengeLeaderDocument = gql`
    query findChallengeLeader($input: Guid!) {
  findChallengeLeader(challengeId: $input) {
    ...ResponseUserParticipantVM
  }
}
    ${ResponseUserParticipantVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FindChallengeLeaderGQL extends Apollo.Query<FindChallengeLeaderQuery, FindChallengeLeaderQueryVariables> {
    document = FindChallengeLeaderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChallengeTechnologyResumeDocument = gql`
    query challengeTechnologyResume($input: Guid!) {
  challengeTechnologyResume(challengeId: $input) {
    id
    token
    description
    selected
    title
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChallengeTechnologyResumeGQL extends Apollo.Query<ChallengeTechnologyResumeQuery, ChallengeTechnologyResumeQueryVariables> {
    document = ChallengeTechnologyResumeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChallengeResumeDocument = gql`
    query challengeResume($input: Input_GeneratePdInput!) {
  challengeResume(challengeId: $input) {
    ...ChallengeResumeVm
  }
}
    ${ChallengeResumeVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChallengeResumeGQL extends Apollo.Query<ChallengeResumeQuery, ChallengeResumeQueryVariables> {
    document = ChallengeResumeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChallengeChooseOtherProjectDocument = gql`
    mutation challengeChooseOtherProject($challengeId: Guid!, $answerId: Guid!, $token: Int!) {
  challengeChooseOtherProject(
    challengeId: $challengeId
    dilemmaQuestionnaireId: $answerId
    token: $token
  ) {
    ...ChallengeResponseVM
  }
}
    ${ChallengeResponseVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChallengeChooseOtherProjectGQL extends Apollo.Mutation<ChallengeChooseOtherProjectMutation, ChallengeChooseOtherProjectMutationVariables> {
    document = ChallengeChooseOtherProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChallengeChooseDilemmaDocument = gql`
    mutation challengeChooseDilemma($input: Input_ChallengeChooseDilemmaRequestVm) {
  challengeChooseDilemma(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChallengeChooseDilemmaGQL extends Apollo.Mutation<ChallengeChooseDilemmaMutation, ChallengeChooseDilemmaMutationVariables> {
    document = ChallengeChooseDilemmaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InitialAccessDocument = gql`
    mutation InitialAccess($input: Input_InitialAccessVM) {
  initialAccess(input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class InitialAccessGQL extends Apollo.Mutation<InitialAccessMutation, InitialAccessMutationVariables> {
    document = InitialAccessDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    ...UserVM
  }
}
    ${UserVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserProgressDocument = gql`
    query UserProgress($input: Guid!) {
  userProgress(userId: $input) {
    ...UserProgressVM
  }
}
    ${UserProgressVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserProgressGQL extends Apollo.Query<UserProgressQuery, UserProgressQueryVariables> {
    document = UserProgressDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation Login($input: String) {
  login(code: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }