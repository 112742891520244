import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {TranslateService} from "@ngx-translate/core";
import * as moment from 'moment';

declare var $: any;

export class BaseComponent {
  language = this.accessLanguage.length === 0 ? 'pt-BR' : this.accessLanguage;

  labels: any = {
    previousLabel: '',
    nextLabel: '',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
  };

  constructor(public router: Router,
              public translate: TranslateService) {
  }

  /*setCulture(culture: string) {
    localStorage.setItem('culture', culture);
  }*/
  set accessCulture(param: string) {
    localStorage.setItem('culture', param);
  }

  get accessCulture(): string {
    return localStorage.getItem('culture') ?? '';
  }

  set accessLanguage(param: string) {
    localStorage.setItem('language', param);
  }

  get accessLanguage(): string {
    return localStorage.getItem('language') ?? '';
  }


  handlerCultureLanguage(_language: string) {
    this.language = _language;
    this.accessCulture = _language;
    this.accessLanguage = _language;
    this.translate.use(_language);
  }

  showMessage(t: string, m: string, type: any = 'warning') {
    Swal.fire({
      title: t,
      html: m,
      icon: type,
      confirmButtonColor: '#032E58',
    });
  }

  confirmMessage(t: string, m: string, callback: any = null) {
    Swal.fire({
      title: t,
      text: m,
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirmButtonColor: '#032E58',
      cancelButtonColor: '#dc3545'
    }).then((result) => {
      if (!this.isNullOrUndefined(result.value) && result.value === true) {
        if (callback != null) {
          callback();
        }
      }
    });
  }

  isNullOrUndefined(value: any, object = false) {
    if (object) {
      return typeof value === 'undefined' || value == null;
    }
    return typeof value === 'undefined' || value == null || value.length === 0;
  }

  validateField(obj: any) {
    let strError = ``;
    obj.map((e: any) => {
      if (this.isNullOrUndefined(e.value)) {
        strError += `${e.text}`;
      }
    });
    return strError;
  }

  toggleLoader(b: boolean): void {
    if (!b) {
      $('#loaderBox').fadeOut();
    } else {
      $('#loaderBox').fadeIn();
    }
  }

  getErrorMessage(s: string) {
    return s === 'required' ? 'Campo obrigatório' : 'Campo inválido';
  }

  getDateFormatString(p: string): string {
    if (p === 'pt-BR') {
      return 'DD/MM/YYYY';
    } else {
      return 'MM/DD/YYYY';
    }
    // return '';
  }

  onError(error: any) {
    // this.loading.hide();
    if (error.status === 401) {
      this.router.navigate(['/logout']);
      return;
    }

    // const e = JSON.parse(error['error']);

    if (error.status === 400) {
      this.showMessage('Atenção', error.error.message, 'warning');
      return;
    }

    if (error.status === 500) {
      this.router.navigate(['/logout']);
      this.showMessage('Atenção', 'Ocorreu um erro com o servidor!.', 'warning');
      return;
    }
  }

  downloadHandlerFile(e:any, fileName:any) {
    const downLoad = $('#btnDownload');
    downLoad.attr('href', e);
    downLoad.attr('download', fileName + '_' + moment().format('DD-MM-YYYY'));
    setTimeout(() => {
      $('#btnDownload')[0].click();
    });
  }

  getNameByType(type: string) {
    let str = '';
    switch (type) {
      case 'centro-de-custo':
        str = 'Centro de custo';
        break;
      case 'ordem':
        str = 'Ordem';
        break;
      case 'reserva':
        str = 'Reserva';
        break;
      case 'diagrama-de-rede':
        str = 'Diagrama de Rede';
        break;
    }
    return str;
  }

  getTypeName(type: string) {
    let str = '';
    switch (type) {
      case 'exit':
        str = 'Saída de Mercadoria';
        break;
      case 'devolution':
        str = 'Devolução de Mercadoria';
        break;
    }
    return str;
  }

  formatResponseTime(time: string): string {
    if (!time) return '';
    const [hours, minutes, seconds] = time.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds?.padStart(2, '0').split('.')[0] || '00'}`;
  }
}


